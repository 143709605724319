/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { UserService } from '../user/user.service';
import { SbrModulosService } from 'app/layout/common/sbr-modulos/sbr-modulos.service';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private api = environment.api;

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _sbrModulosService: SbrModulosService
    ) {}

    set authenticated$(auth: boolean) { this._authenticated = auth; }
    get accessToken(): string { return AuthUtils.getCookie('_atk_pu'); }
    get refreshToken(): string { return AuthUtils.getCookie('_rtk_pu'); }

    forgotPassword(email: string): Observable<any>
    {
        const header = new HttpHeaders({
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': environment.url
        });
        return this._httpClient.post(this.api + '/auth/recovery-password', {email}, {headers: header});
    }

    resetPassword(password: string, token: string): Observable<any>
    {
        const header = new HttpHeaders({
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
          });
        return this._httpClient.post(this.api + '/auth/reset-password', {password}, {headers: header});
    }

    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        if ( this._authenticated ) { return throwError('User is already logged in.'); }
        let moduleType = 'allowed-pickingup-hom';
        switch (environment.ambientserver) {
            case 'release':
            case 'local':
                moduleType = 'allowed-pickingup-stage';
            break;
            case 'development':
                moduleType = 'allowed-pickingup-hom';
            break;
            case 'production':
                moduleType = 'allowed-pickingup-prod';
            break;
        }
        const params = {
            'grant_type' : 'password',
            'client_id' : environment.client_id,
            'client_secret' : environment.client_secret,
            'username' : credentials.email,
            'password' : credentials.password,
            'type_module' : moduleType,
            'scope' : '',
        };
        return this._httpClient.post(this.api + '/auth/login', params).pipe(
            switchMap((response: any) => {
                if (response.access_token) {
                    AuthUtils.setCookie({name: '_atk_pu', value: response.access_token});
                    AuthUtils.setCookie({name: '_rtk_pu', value: response.refresh_token});
                    this._authenticated = true;
                    this._userService.user = response.user;
                    this._sbrModulosService.sbrmodulos$ = response.user.scopes;
                    const user = btoa(JSON.stringify(response.user));
                    AuthUtils.setCookie({name: '_us_pu', value: user, session: true});
                    return of(response);
                } else {
                    return of(false);
                }
            })
        );
    }

    signInUsingToken(): Observable<any>
    {
        return this._httpClient.post(this.api + '/auth/refresh-access-token', {}).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                if (response.access_token) {
                    // Store the access token in the local storage
                    AuthUtils.setCookie({name: '_atk_pu', value: response.access_token});
                    AuthUtils.setCookie({name: '_rtk_pu', value: response.refresh_token});
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response.user;
                    this._sbrModulosService.sbrmodulos$ = response.user.scopes;
                    const user = btoa(JSON.stringify(response.user));
                    AuthUtils.setCookie({name: '_us_pu', value: user, session: true});
                    return of(true);
                } else {
                    return of(false);
                }
            })
        );
    }

    signOut(): Observable<any>
    {
        AuthUtils.deleteCookie('_atk_pu');
        AuthUtils.deleteCookie('_rtk_pu');
        AuthUtils.deleteCookie('_us_pu');
        localStorage.clear();
        sessionStorage.clear();
        this._authenticated = false;
        return of(true);
    }

    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    check(): Observable<boolean>
    {
         if ( !this.accessToken ||  this.accessToken === undefined) { return of(false); }
         if ( !this.refreshToken ||  this.refreshToken === undefined) { return of(false); }
         if ( AuthUtils.isTokenExpired(this.accessToken) ) {
            return this.signInUsingToken();
        } else {
            this._userService.recoveryUserSession();
            return of(true);
        }
    }
}
