import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    constructor(private _httpClient: HttpClient)
    {
    }

    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getAdmin(): Observable<Navigation>
    {
        const url = 'api/common/navigation/admin';
        return this._httpClient.get<Navigation>(url).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getClient(): Observable<Navigation>
    {
        const url = 'api/common/navigation/client';
        return this._httpClient.get<Navigation>(url).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getClientAutonomous(): Observable<Navigation>
    {
        const url = 'api/common/navigation/client-autonomous';
        return this._httpClient.get<Navigation>(url).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getOperation(): Observable<Navigation>
    {
        const url = 'api/common/navigation/operation';
        return this._httpClient.get<Navigation>(url).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }
}
