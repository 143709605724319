import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JodaxGuard implements CanActivate {

  constructor(
    private _userService: UserService,
    private _router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
      return this._userService.user$
      .pipe(
        switchMap((res: any) => {
          if (res) {
            const userType = res.client.type;
            console.log(userType);
            if (userType === 'JODAX') {
              return of(true);
            } else {
              this._router.navigate(['sign-in']);
              return of(false);
            }
          } else {
            this._router.navigate(['sign-in'], {queryParams: {redirectUrl}});
          }
        })
      );
  }
}
