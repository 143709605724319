/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultOperationNavigation: FuseNavigationItem[] = [
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: [
            {
                id   : 'operation.list',
                title: 'Listagem',
                subtitle: 'Lista de operações pendentes',
                type : 'basic',
                icon : 'mat_outline:dvr',
                link : '/order-operation/grid'
            },
            {
                id   : 'operation.storage',
                title: 'Armazenagem',
                subtitle: 'Operação de armazenagem',
                type : 'basic',
                icon : 'mat_outline:inventory_2',
                link : '/order-operation/storage'
            },
            {
                id   : 'operation.moviment',
                title: 'Movimentação',
                subtitle: 'Operação de movimentação',
                type : 'basic',
                icon : 'mat_outline:compare_arrows',
                link : '/order-operation/transfer'
            },
            {
                id   : 'operation.picking',
                title: 'Picking',
                subtitle: 'Operação de picking',
                type : 'basic',
                icon : 'mat_outline:widgets',
                link : '/order-operation/picking'
            },
            {
                id   : 'operation.reader',
                title: 'QRCODE',
                subtitle: 'Leitura de QRCode',
                type : 'basic',
                icon : 'mat_outline:qr_code',
                link : '/order-operation/reader-qrcode'
            }
        ]
    }

];
export const compacOperationtNavigation: FuseNavigationItem[] = [
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    }
];
export const futuristicOperationNavigation: FuseNavigationItem[] = [
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    }
];
export const horizontalOperationNavigation: FuseNavigationItem[] = [
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    }
];
