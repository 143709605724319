import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { ReportsAutonomousService } from './reports-autonomous.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsAutonomousResolver implements Resolve<boolean> {

  constructor(
    private _reportAutonomousService: ReportsAutonomousService
  )
  { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this._reportAutonomousService.list(),
    ]);
  }
}
