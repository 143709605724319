import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { assign, cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class OrderApi
{
    private _orders: any = [];

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {
        // GET
        this._fuseMockApiService.onGet('api/common/warehouse/order').reply(() => {
            if (localStorage.getItem('orders') !== undefined) {
                const dt = JSON.parse(localStorage.getItem('orders'));
                this._orders = dt;
            }
            return [200, cloneDeep(this._orders)];
        });
        // POST
        this._fuseMockApiService.onPost('api/common/warehouse/order').reply(({request}) => {
            const newOrder = cloneDeep(request.body);
            newOrder.id = FuseMockApiUtils.guid();
            newOrder.status = 0;
            this._orders.unshift(newOrder);
            const dt = this._orders;
            localStorage.setItem('orders', JSON.stringify(dt));
            return [200, newOrder];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/order').reply(({request}) => {
            const id = request.body.id;
            const armazem = cloneDeep(request.body);

            let updatedOrder = null;

            this._orders.forEach((item: any, index: number, orders: any[]) => {
                if ( item.id === id) {
                    orders[index] = assign({}, orders[index], armazem);
                    updatedOrder = orders[index];
                }
            });
            localStorage.setItem('orders', JSON.stringify(this._orders));
            return [200, updatedOrder];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/order/finish').reply(({request}) => {
            const id = request.params.get('id');
            let updatedOrder = null;
            this._orders.forEach((item: any, index: number, orders: any[]) => {
                if ( item.id === id) {
                    orders[index].status = 1;
                    updatedOrder = orders[index];
                }
            });
            localStorage.setItem('orders', JSON.stringify(this._orders));
            return [200, updatedOrder];
        });
        // SEARCH ORDER BY MERCADORIA
        this._fuseMockApiService.onGet('api/common/warehouse/order/product').reply(({request}) => {
            const id = request.params.get('id');
            const index = this._orders.findIndex((item: any) => item.product_id === id);
            if (index > -1) {
                return [200, this._orders[index]];
            } else {
                return [400, 'Nenhum pedido de movimentação encontrado para esta mercadoria'];
            }
        });
        // SEARCH ORDER BY STORAGE
        this._fuseMockApiService.onGet('api/common/warehouse/order/storage').reply(({request}) => {
            const id = request.params.get('id');
            const index = this._orders.findIndex((item: any) => item.storage_id === id);
            if (index > -1) {
                return [200, this._orders[index]];
            } else {
                return [400, 'Nenhum pedido de movimentação encontrado para este armazem'];
            }
        });
        // DELETE
        this._fuseMockApiService.onDelete('api/common/warehouse/order').reply(({request}) => {
            const id = request.params.get('id');
            let deletedOrder = null;
            const index = this._orders.findIndex((item: any) => item.id === id);
            deletedOrder = cloneDeep(this._orders[index]);
            this._orders.splice(index, 1);
            localStorage.setItem('orders', JSON.stringify(this._orders));
            return [200, deletedOrder];
        });

    }
}
