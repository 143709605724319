/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultClientAutonomousNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Armazém, produtos, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'storage',
                title: 'Armazém',
                type : 'group',
                icon : 'forklift',
                children: [
                    {
                        id   : 'storages',
                        title: 'Cadastrar armazém',
                        type : 'basic',
                        icon : 'transport-trolley',
                        link : '/cadastro/storage'
                    },
                    {
                        id   : 'rules',
                        title: 'Regras de armazenagem',
                        type : 'basic',
                        icon : 'rule',
                        link : '/cadastro/rules'
                    }
                ]
            }
        ]
    },
    {
        id   : 'autonomous',
        title: 'Automação',
        subtitle: 'Solicitação de processos automatizados',
        type : 'group',
        icon : 'mat_outline:precision_manufacturing',
        children: [
            {
                id   : 'autonomous.autonomousProduction',
                title: 'Ordem de produção',
                subtitle: 'Ordem de produção de processo automatizado',
                type : 'basic',
                icon : 'mat_outline:precision_manufacturing',
                link : '/order-autonomous/autonomous-production'
            },
            {
                id   : 'autonomous.autonomousPicking',
                title: 'Ordem de picking',
                subtitle: 'Ordem de picking de processo automatizado',
                type : 'basic',
                icon : 'mat_solid:add_shopping_cart',
                link : '/order-autonomous/autonomous-picking'
            },
        ]
    },
    {
        id   : 'autonomous.operations',
        title: 'Operações automatizadas',
        type : 'group',
        icon : 'mat_outline:dvr',
        children : [
            {
                id   : 'autonomous.operationProd',
                title: 'Operação de produção',
                subtitle: 'Operações de produção executadas',
                type : 'basic',
                icon : 'mat_outline:precision_manufacturing',
                link : '/operation-autonomous/autonomous-production'
            },
            {
                id   : 'autonomous.operationOrder',
                title: 'Operação de armazenamento',
                subtitle: 'Operações de armazenamento executadas',
                type : 'basic',
                icon : 'mat_outline:archive',
                link : '/operation-autonomous/autonomous-order'
            },
            {
                id   : 'autonomous.operationPicking',
                title: 'Operação de picking',
                subtitle: 'Operações de picking executadas',
                type : 'basic',
                icon : 'mat_outline:add_shopping_cart',
                link : '/operation-autonomous/autonomous-picking'
            },
        ]
    },
    {
        id   : 'inventory',
        title: 'Inventário',
        subtitle: 'Gerenciamento de inventário',
        type : 'group',
        icon : 'mat_outline:inventory_2',
        children: [
            {
                id   : 'inventory.manager',
                title: 'Solicitação',
                subtitle: 'Solicitação de inventário',
                type : 'basic',
                icon : 'mat_outline:inventory',
                link : '/autonomous-inventory'
            }
        ]
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'group',
        icon : 'mat_outline:summarize',
        children: [
            {
                id   : 'report.list',
                title: 'Relátorios',
                subtitle: 'Lista de todas as operações',
                type : 'basic',
                icon : 'mat_outline:summarize',
                link : '/dashboard-autonomous'
            }
        ]
    }

];
export const compactClientAutonomousNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'autonomous',
        title: 'Automação',
        subtitle: 'Solicitação de processos automatizados',
        type : 'aside',
        icon : 'mat_outline:precision_manufacturing',
        children: []
    },
    {
        id   : 'autonomous.operations',
        title: 'Operações automatizadas',
        type : 'aside',
        icon : 'mat_outline:dvr',
        children: []
    },
    {
        id   : 'inventory',
        title: 'Inventário',
        subtitle: 'Gerenciamento de inventário',
        type : 'aside',
        icon : 'mat_outline:inventory_2',
        children : []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'aside',
        icon : 'mat_outline:summarize',
        children: []
    }
];
export const futuristicClientAutonomousNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'autonomous',
        title: 'Automação',
        subtitle: 'Solicitação de processos automatizados',
        type : 'aside',
        icon : 'mat_outline:precision_manufacturing',
        children: []
    },
    {
        id   : 'autonomous.operations',
        title: 'Operações automatizadas',
        type : 'aside',
        icon : 'mat_outline:dvr',
        children: []
    },
    {
        id   : 'inventory',
        title: 'Inventário',
        subtitle: 'Gerenciamento de inventário',
        type : 'aside',
        icon : 'mat_outline:inventory_2',
        children : []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'aside',
        icon : 'mat_outline:summarize',
        children: []
    }
];
export const horizontalClientAutonomousNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'autonomous',
        title: 'Automação',
        subtitle: 'Solicitação de processos automatizados',
        type : 'aside',
        icon : 'mat_outline:precision_manufacturing',
        children: []
    },
    {
        id   : 'autonomous.operations',
        title: 'Operações automatizadas',
        type : 'aside',
        icon : 'mat_outline:dvr',
        children: []
    },
    {
        id   : 'inventory',
        title: 'Inventário',
        subtitle: 'Gerenciamento de inventário',
        type : 'aside',
        icon : 'mat_outline:inventory_2',
        children : []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'aside',
        icon : 'mat_outline:summarize',
        children: []
    }
];
