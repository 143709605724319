/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthenticateAccessGuard } from './modules/auth/authenticate-access/authenticate-access.guard';
import { AuthenticateAccessResolver } from './modules/auth/authenticate-access/authenticate-access.resolver';
import { ListOperatorGuard } from './modules/pages/list-operator/list-operator.guard';
import { ListOperatorResolver } from './modules/pages/list-operator/list-operator.resolver';
import { StorageOperatorResolver } from './modules/pages/storage-operator/storage-operator.resolver';
import { MovimentOperatorResolver } from './modules/pages/moviment-operator/moviment-operator.resolver';
import { OperatorGuard } from './core/auth/guards/operator.guard';
import { EmbarcadorGuard } from './core/auth/guards/embarcador.guard';
import { PickingOperatorResolver } from './modules/pages/picking-operator/picking-operator.resolver';
import { ReportGuard } from './modules/pages/reports/report.guard';
import { ReportResolver } from './modules/pages/reports/report.resolver';
import { JodaxGuard } from './core/auth/guards/jodax.guard';
import { EmbarcadorAutonomousGuard } from './core/auth/guards/embarcador-autonomous.guard';
import { EmbarcadorGeneralGuard } from './core/auth/guards/embarcador-general.guard';
import { ReportsAutonomousResolver } from './modules/pages/reports-autonomous/reports-autonomous.resolver';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },
    {
        path: 'authenticate',
        canActivate: [AuthenticateAccessGuard],
        resolve: {initialData: AuthenticateAccessResolver},
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/auth/authenticate-access/authenticate-access.module').then(m => m.AuthenticateAccessModule)},
        ]
    },
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },
    {
        path       : '',
        canActivate: [AuthGuard, EmbarcadorGuard, ReportGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/pages/reports/report.module').then(m => m.ReportModule)}
        ]
    },
    {
        path       : 'cadastro',
        canActivate: [AuthGuard, EmbarcadorGeneralGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            { path: '', redirectTo: 'storage', pathMatch: 'full'},
            {path: 'embarcadora', canActivate: [JodaxGuard], loadChildren: () => import('app/modules/pages/cadastros/embarcadora/embarcadora.module').then(m => m.EmbarcadoraModule)},
            {path: 'produtos', loadChildren: () => import('app/modules/pages/cadastros/produtos/produtos.module').then(m => m.ProdutosModule)},
            {path: 'storage', loadChildren: () => import('app/modules/pages/cadastros/armazens/armazens.module').then(m => m.ArmazensModule)},
            {path: 'rules', loadChildren: () => import('app/modules/pages/cadastros/rules/rules.module').then(m => m.RulesModule)},
        ]
    },
    {
        path       : 'order',
        canActivate: [AuthGuard, EmbarcadorGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'storage', loadChildren: () => import('app/modules/pages/orders/storage/storage-order.module').then(m => m.StorageOrderModule)},
            {path: 'picking', loadChildren: () => import('app/modules/pages/orders/picking/picking-order.module').then(m => m.PickingOrderModule)},
        ]
    },
    {
        path       : 'order-autonomous',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'autonomous-production', loadChildren: () => import('app/modules/pages/autonomous-production/autonomous-production.module').then(m => m.AutonomousProductionModule)},
            {path: 'autonomous-picking', loadChildren: () => import('app/modules/pages/autonomous-picking/autonomous-picking.module').then(m => m.AutonomousPickingModule)},
        ]
    },
    {
        path       : 'autonomous-inventory',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/autonomous-inventory/autonomous-inventory.module').then(m => m.AutonomousInventoryModule)},
        ]
    },
    {
        path       : 'order-operation',
        canActivate: [AuthGuard, OperatorGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'grid', resolve: {initData: ListOperatorResolver}, canActivate: [ListOperatorGuard], loadChildren: () => import('app/modules/pages/list-operator/list-operator.module').then(m => m.ListOperatorModule)},
            {path: 'storage', resolve: {initData: StorageOperatorResolver}, canActivate: [ListOperatorGuard], loadChildren: () => import('app/modules/pages/storage-operator/storage-operator.module').then(m => m.StorageOperatorModule)},
            {path: 'transfer', resolve: {initData: MovimentOperatorResolver}, canActivate: [ListOperatorGuard], loadChildren: () => import('app/modules/pages/moviment-operator/moviment-operator.module').then(m => m.MovimentOperatorModule)},
            {path: 'picking', resolve: {initData: PickingOperatorResolver}, canActivate: [ListOperatorGuard], loadChildren: () => import('app/modules/pages/picking-operator/picking-operator.module').then(m => m.PickingOperatorModule)},
            {path: 'reader-qrcode', canActivate: [ListOperatorGuard], loadChildren: () => import('app/modules/pages/qrcode-reader/qrcode-reader.module').then(m => m.QrcodeReaderModule)},
        ]
    },
    {
        path       : 'operation',
        canActivate: [AuthGuard, EmbarcadorGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/operation/operation.module').then(m => m.OperationModule)},
        ]
    },
    {
        path       : 'operation-autonomous',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver
        },
        children   : [
            {path: 'autonomous-production', loadChildren: () => import('app/modules/pages/autonomous-production-report/autonomous-production-report.module').then(m => m.AutonomousProductionReportModule)},
            {path: 'autonomous-order', loadChildren: () => import('app/modules/pages/autonomous-order-report/autonomous-order-report.module').then(m => m.AutonomousOrderReportModule)},
            {path: 'autonomous-picking', loadChildren: () => import('app/modules/pages/autonomous-picking-report/autonomous-picking-report.module').then(m => m.AutonomousPickingReportModule)},
        ]
    },
    {
        path       : 'dashboard-autonomous',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard, ReportGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportsAutonomousResolver
        },
        component  : LayoutComponent,
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/reports-autonomous/reports-autonomous.module').then(m => m.ReportsAutonomousModule)}
        ]
    },
    {
        path       : 'reports',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard, ReportGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        component  : LayoutComponent,
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/reports/report.module').then(m => m.ReportModule)}
        ]
    },
    {
        path       : 'administration',
        canActivate: [AuthGuard, JodaxGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        component  : LayoutComponent,
        children   : [
            {path: 'closure', loadChildren: () => import('app/modules/pages/fechamento/fechamento.module').then(m => m.FechamentoModule)}
        ]
    }
];
