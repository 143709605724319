import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from 'app/core/pagination/pagination.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListOperatorService {

  private api = environment.api_backend;

  private _orders: BehaviorSubject<any> = new BehaviorSubject([]);
  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }
  set _orders$(w: any) { this._orders.next(w); }
  get _orders$(): Observable<any> { return this._orders.asObservable(); }

  constructor(
    private _http: HttpClient
  ) { }

  list(page: number = 0, size: number = 50, sort: string = 'timestamp', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
  Observable<{ meta: IPagination; data: any[] }>
  {
    return this._http.get<any>(this.api + '/order-operation/order-operations', {
        params: { page: '' + page, size: '' + size, sort, order, search }
    }).pipe(
      tap((response) => {
        const dados = response.data;
        const pagination: IPagination = {
          length: dados.total,
          size: dados.per_page,
          page: dados.current_page,
          lastPage: dados.last_page,
          startIndex: 0,
          endIndex: 0
        };
        this._pagination.next(pagination);
        this._orders.next(dados);
    })
    );
  }

}
