import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { MovimentOperatorService } from './moviment-operator.service';

@Injectable({
  providedIn: 'root'
})
export class MovimentOperatorResolver implements Resolve<boolean> {

  constructor(
    private _movimentOperationSrv: MovimentOperatorService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this._movimentOperationSrv.list()
    ]);
  }
}
