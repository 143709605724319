import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { environment } from 'environments/environment';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    ambient = environment.ambientserver;
    version = environment.version;

    constructor(
        private _router: Router,
        private _splashSrv: FuseSplashScreenService
    )
    {
        this._router.events.subscribe((e: RouterEvent) => {
            this.navigationInterceptor(e);
        });
    }

    navigationInterceptor(event: RouterEvent): void
    {
        if (event instanceof NavigationStart) {
            this._splashSrv.show();
        }
        if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
            this._splashSrv.hide();
        }
    }
}
