/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultClientNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Armazém, produtos, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'storage_products',
                title: 'Produtos',
                type : 'group',
                icon : 'inventory_2',
                children: [
                    {
                        id   : 'createProd',
                        title: 'cadastrar produto',
                        subtitle: 'Cadastro manual de produto',
                        type : 'basic',
                        icon : 'inventory_2',
                        link : '/cadastro/produtos/create'
                    },
                    {
                        id   : 'prod_upload',
                        title: 'Upload planilha',
                        subtitle: 'Cadastro de produto via planilha',
                        type : 'basic',
                        icon : 'cloud_upload',
                        link : '/cadastro/produtos/upload'
                    },
                    {
                        id   : 'prod_tag',
                        title: 'Impressão de etiqueta',
                        type : 'basic',
                        icon : 'style',
                        link : '/cadastro/produtos/tag'
                    }
                ]
            },
            {
                id   : 'storage',
                title: 'Armazém',
                type : 'group',
                icon : 'forklift',
                children: [
                    {
                        id   : 'storages',
                        title: 'Cadastrar armazém',
                        type : 'basic',
                        icon : 'transport-trolley',
                        link : '/cadastro/storage'
                    },
                    {
                        id   : 'rules',
                        title: 'Regras de armazenagem',
                        type : 'basic',
                        icon : 'rule',
                        link : '/cadastro/rules'
                    }
                ]
            }
        ]
    },
    {
        id   : 'order',
        title: 'Solicitação',
        subtitle: 'Solicitação de ordens',
        type : 'group',
        icon : 'heroicons_outline:desktop-computer',
        children: [
            {
                id   : 'order.storage',
                title: 'Ordem de estocagem',
                subtitle: 'Abertura de ordem de estocagem',
                type : 'basic',
                icon : 'mat_outline:published_with_changes',
                link : '/order/storage'
            },
            {
                id   : 'order.picking',
                title: 'Ordem de saída',
                subtitle: 'Saída de mercadoria',
                type : 'basic',
                icon : 'mat_outline:downloading',
                link : '/order/picking'
            }
        ]
    },
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Acompanhamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: [
            {
                id   : 'operation.list',
                title: 'Listagem',
                subtitle: 'Lista de todas as operações',
                type : 'basic',
                icon : 'mat_outline:dvr',
                link : '/operation/list'
            },
            {
                id   : 'operation.storage',
                title: 'Armazenamento',
                subtitle: 'Lista de operações de armazenamento',
                type : 'basic',
                icon : 'mat_outline:inventory_2',
                link : '/operation/storage'
            },
            {
                id   : 'operation.moviment',
                title: 'Movimentação',
                subtitle: 'Lista de operações de movimentação',
                type : 'basic',
                icon : 'mat_outline:compare_arrows',
                link : '/operation/moviment'
            },
            {
                id   : 'operation.picking',
                title: 'Picking',
                subtitle: 'Lista de operações de picking',
                type : 'basic',
                icon : 'mat_outline:widgets',
                link : '/operation/picking'
            },
        ]
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'group',
        icon : 'mat_outline:summarize',
        children: [
            {
                id   : 'report.list',
                title: 'Relátorios',
                subtitle: 'Lista de todas as operações',
                type : 'basic',
                icon : 'mat_outline:summarize',
                link : '/reports'
            }
        ]
    }

];
export const compactClientNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'order',
        title: 'Solicitação',
        subtitle: 'Solicitação de ordens',
        type : 'aside',
        icon : 'heroicons_outline:desktop-computer',
        children: []
    },
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'group',
        icon : 'mat_outline:summarize',
        children: []
    }
];
export const futuristicClientNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'order',
        title: 'Solicitação',
        subtitle: 'Solicitação de ordens',
        type : 'aside',
        icon : 'heroicons_outline:desktop-computer',
        children: []
    },
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'group',
        icon : 'mat_outline:summarize',
        children: []
    }
];
export const horizontalClientNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastros',
        subtitle: 'Armazém, produtos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'order',
        title: 'Solicitação',
        subtitle: 'Solicitação de ordens',
        type : 'aside',
        icon : 'heroicons_outline:desktop-computer',
        children: []
    },
    {
        id   : 'operation',
        title: 'Operação',
        subtitle: 'Gerenciamento de operação de movimentação',
        type : 'group',
        icon : 'mat_outline:published_with_changes',
        children: []
    },
    {
        id   : 'reports',
        title: 'Estatísticas',
        subtitle: 'Métricas e relatórios',
        type : 'group',
        icon : 'mat_outline:summarize',
        children: []
    }
];
