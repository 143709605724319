import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();
        if (
            !req.url.includes('assets') &&
            !req.url.includes('oauth') &&
            !req.url.includes('viacep') &&
            !req.url.includes('nominatim') &&
            !req.url.includes('openstreetmap') &&
            !req.url.includes('s3') &&
            !req.url.includes('password-recovery') &&
            !req.url.includes('reset-password') &&
            !req.url.includes('mapbox'))
        {
            if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
            {
                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
                });
            }
            if (req.url.includes('uploads')) {
                newReq = req.clone({
                    headers: req.headers.set('Content-type', '; charset=utf-8; boundary=' + Math.random().toString())
                });
                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
                });
            }
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }
}
