import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmbarcadorGeneralGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  constructor(
    private _userService: UserService,
    private _router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | boolean | UrlTree | any
    {
      const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
      this._userService.recoveryUserSession();
      return this._userService.user$
      .pipe(
        switchMap((res: any) => {
          if (res) {
            const userType = res.client.type;
            if (userType === 'EMBARCADOR' || userType === 'FACTORY' || userType === 'INDUSTRY' || userType === 'JODAX') {
              const rolePermission = res.role_id;
              if (rolePermission !== 2 && rolePermission !== 1 && rolePermission !== 22) {
                this._router.navigate(['order-operation', 'grid']);
                return of(false);
              }
              return of(true);
            } else {
              return of(false);
            }
          } else {
            this._router.navigate(['sign-in'], {queryParams: {redirectUrl}});
          }
        })
      );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
