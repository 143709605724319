import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from 'app/core/pagination/pagination.types';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PickingOperatorService
{
  private api = environment.api_backend;

  private _orders: BehaviorSubject<any> = new BehaviorSubject([]);
  private _pickingOrder: BehaviorSubject<boolean|string> = new BehaviorSubject(false);
  private _pickingOrderId: BehaviorSubject<boolean|string> = new BehaviorSubject(false);
  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
  private _product: BehaviorSubject<any | null> = new BehaviorSubject(false);
  private _operationStorage: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  private _operationStorages: BehaviorSubject<any | null> = new BehaviorSubject([]);

  set pickingOrderId$(id: any) { this._pickingOrderId.next(id); }
  get pickingOrderId$(): Observable<any> { return this._pickingOrderId.asObservable(); }
  get pickingOrder$(): Observable<any> { return this._pickingOrder.asObservable(); }

  set operationStorage$(storage) { this._operationStorage.next(storage); }
  get operationStorage$(): Observable<any> { return this._operationStorage.asObservable(); }

  set product$(product: any) { this._product.next(product); }
  get product$(): Observable<any> { return this._product.asObservable(); }

  get operationStorages$(): Observable<any> { return this._operationStorages.asObservable(); }
  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }
  set _orders$(w: any) { this._orders.next(w); }
  get _orders$(): Observable<any> { return this._orders.asObservable(); }

  constructor(
    private _http: HttpClient
  ) { }

  list(page: number = 0, size: number = 50, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
  Observable<{ meta: IPagination; data: any[] }>
  {
    return this._http.get<any>(this.api + '/order-operation/picking', {
        params: { page: '' + page, size: '' + size, sort, order, search }
    }).pipe(
      tap((response) => {
        const dados = response.data;
        const pagination: IPagination = {
          length: dados.total,
          size: dados.per_page,
          page: dados.current_page,
          lastPage: dados.last_page,
          startIndex: 0,
          endIndex: 0
        };
        this._pagination.next(pagination);
        this._orders.next(dados);
    })
    );
  }

  public recoverySession(): void
  {
    if (sessionStorage.getItem('_pkop_order') !== undefined) {
      const order = JSON.parse(sessionStorage.getItem('_pkop_order'));
      this._pickingOrder.next(order);
    }
  }

  public recoveryStorageSession(): void
  {
    if (sessionStorage.getItem('_pkop_storage') !== undefined) {
      const storage = JSON.parse(sessionStorage.getItem('_pkop_storage'));
      this._operationStorage.next(storage);
    }
  }

  public recoveryProductSession(): void
  {
    if (sessionStorage.getItem('_pkop_product') !== undefined) {
      const product = JSON.parse(sessionStorage.getItem('_pkop_product'));
      this._product.next(product);
    }
  }

  public getProducts(): Observable<any>
  {
    const pickingOrderId = this._pickingOrderId.value;
    if (pickingOrderId !== undefined && pickingOrderId !== false) {
      return this._http.get(this.api + `/order-picking/resume/${pickingOrderId}`).pipe(
        tap((res: any) => {
          if (res.data) {
            const order = res.data;
            sessionStorage.setItem('_pkop_order', JSON.stringify(order));
            this._pickingOrder.next(res.data);
          } else {
            sessionStorage.removeItem('_pkop_order');
            this._pickingOrder.next(false);
          }
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
    } else {
      return of(false);
    }
  }

  public startOperation(pickingOrderId: string): Observable<unknown>
  {
    return this._http.get(this.api + `/order-operation/picking/start/${pickingOrderId}`).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeLocation(pickingOrderId: string, virtualStorageId: string, storageActual: any): Observable<any>
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this._http.get(this.api + `/order-operation/picking/location/${pickingOrderId}/${virtualStorageId}`).pipe(
      tap(res => res),
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeProduct(dados: any): Observable<any>
  {
    const pickingOrderId = dados.pickingOrderId;
    const productId = dados.productId;
    const virtualStorageId = dados.virtualStorageId;
    return this._http.post(this.api + `/order-operation/picking/product/${pickingOrderId}/${productId}/${virtualStorageId}`, {inputQuantity: dados.inputQuantity}).pipe(
      tap((res: any) => {
        const data = res.data;
        dados.isCompleted = data.isCompleted;
        dados.isFinished = data.isFinished;
        dados.allocated = data.allocated;
        dados.remainQta = data.remainQta;
        this._updateOrder(dados);
      }),
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public finishOperation(clients: any): Observable<any>
  {
    return this._http.post(this.api + '/order-operation/picking/finish', {clients}).pipe(
      tap(() => {
        sessionStorage.removeItem('_pkop_order');
        sessionStorage.removeItem('_pkop_storage');
        sessionStorage.removeItem('_pkop_product');
      }),
      map(() => true),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  private _updateOrder(data: any): void
  {
    this.recoverySession();
    const order: any = cloneDeep(this._pickingOrder.value);
    order.is_finished = data.isFinished;
    order.status = (data.isFinished)? 3 : 2;
    const product = order.products.find((el: any) => el.id === data.productId);
    const storageId = data.storageId;
    const virtualStorageId = data.virtualStorageId;
    const lote = data.lote;
    const virtualStorages = product.storages[storageId].virtuais;
    const virtual = virtualStorages.find((el: any) => el.id === virtualStorageId && el.lote === lote);
    virtual.allocated = data.allocated;
    virtual.available = data.remainQta;
    virtual.isCompleted = data.isCompleted;
    this._pickingOrder.next(order);
    sessionStorage.setItem('_pkop_order', JSON.stringify(order));
    sessionStorage.removeItem('_pkop_storage');
    sessionStorage.removeItem('_pkop_product');
  }


}
