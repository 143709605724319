/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessService {

  private api = environment.api;

  constructor(
    private _httpClient: HttpClient
    )
  {}

  authenticateAccess(token: string, bearer: string): any
  {
    const bearerToken = 'Bearer ' + bearer;
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': bearerToken
    });
    return this._httpClient.get(this.api + `/auth/authenticate-module/${token}`, {headers: header})
    .pipe(
      map(() => {
        AuthUtils.setCookie({name: '_atk_pu', value: bearer, expireDays: 2});
        AuthUtils.setCookie({name: '_rtk_pu', value: bearer, expireDays: 2});
        return true;
      }),
      catchError(() => {
        AuthUtils.deleteCookie('_atk_pu');
        AuthUtils.deleteCookie('_rtk_pu');
        AuthUtils.deleteCookie('_us_pu');
        return of(false);
      })
    );
  }
}
