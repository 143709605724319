import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    )
    {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('jodaxicons', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/jodaxicons.svg'));
        this._matIconRegistry.addSvgIcon('lift', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/jodax_iconset_lift.svg'));
        this._matIconRegistry.addSvgIcon('lift_full', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/jodax_iconset_lift_full.svg'));
        this._matIconRegistry.addSvgIcon('bigbag', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/jodax_iconset_bigbag.svg'));
        this._matIconRegistry.addSvgIcon('warehouse', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-877-warehouse.svg'));
        this._matIconRegistry.addSvgIcon('forklift', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-873-forklift.svg'));
        this._matIconRegistry.addSvgIcon('transport-trolley', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-874-transport-trolley.svg'));
        this._matIconRegistry.addSvgIcon('simple-trolley', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-875-simple-trolley.svg'));
        this._matIconRegistry.addSvgIcon('palette-package', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/palette-package.svg'));
        this._matIconRegistry.addSvgIcon('package', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-871-package.svg'));
        this._matIconRegistry.addSvgIcon('time-delivery', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-872-one-day-delivery.svg'));
        this._matIconRegistry.addSvgIcon('notes', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-40-notes.svg'));
        this._matIconRegistry.addSvgIcon('truck', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-59-truck.svg'));
        this._matIconRegistry.addSvgIcon('wheat', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-783-wheat.svg'));
        this._matIconRegistry.addSvgIcon('tractor', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-699-tractor.svg'));
        this._matIconRegistry.addSvgIcon('access-point', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-33-access-point.svg'));
        this._matIconRegistry.addSvgIcon('wifi', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-74-wifi.svg'));
        this._matIconRegistry.addSvgIcon('user', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-4-user.svg'));
        this._matIconRegistry.addSvgIcon('user-plus', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-7-user-plus.svg'));
        this._matIconRegistry.addSvgIcon('user-minus', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-8-user-minus.svg'));
        this._matIconRegistry.addSvgIcon('user-group', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-44-user-group.svg'));
        this._matIconRegistry.addSvgIcon('leaves', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-285-tea-leaves.svg'));
        this._matIconRegistry.addSvgIcon('coffee', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-284-coffee-beans.svg'));
        this._matIconRegistry.addSvgIcon('transfer', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/SVG/glyphicons-basic-487-exchange.svg'));
    }
}
