import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ListOperatorService } from './list-operator.service';

@Injectable({
  providedIn: 'root'
})
export class ListOperatorResolver implements Resolve<boolean> {

  constructor(
    private _listOperationSrv: ListOperatorService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this._listOperationSrv.list()
    ]);
  }
}
