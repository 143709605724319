import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SbrModulosService } from './sbr-modulos.service';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'sbrmodulos',
  templateUrl: './sbr-modulos.component.html',
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'sbrmodulos'
})
export class SbrModulosComponent implements OnInit, OnDestroy
{

  @ViewChild('sbrmodulosOrigin') private _sbrmodulosOrigin: MatButton;
  @ViewChild('sbrmodulosPanel') private _sbrmodulosPanel: TemplateRef<any>;

  mode: 'view'  = 'view';
  sbrModulos: any[];
  showLoader: boolean = false;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<void>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
    private _sbrModulosService: SbrModulosService,
  ) { }

  ngOnInit(): void
  {
    this._sbrModulosService.sbrmodulos$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((sbrmodulos: any[]) => {
        this.sbrModulos = sbrmodulos;
        this._changeDetectorRef.markForCheck();
    });
  }

  openPanel(): void
  {
      if ( !this._sbrmodulosPanel || !this._sbrmodulosOrigin ) {return;}
      if ( !this._overlayRef ) {this._createOverlay();}
      // Attach the portal to the overlay
      this._overlayRef.attach(new TemplatePortal(this._sbrmodulosPanel, this._viewContainerRef));
  }

  closePanel(): void
  {
      this._overlayRef.detach();
  }

  redirectToModulo(modulo: string): void
  {
    this.showLoader = true;
    this._changeDetectorRef.markForCheck();
    this._sbrModulosService.redirectAuthenticate(modulo)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      (res: any) => window.location = res.url,
      (error) => {
        this.showLoader = false;
        this._changeDetectorRef.markForCheck();
      },
    );
  }

  trackByFn(index: number, item: any): any
  {
      return item.id || index;
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();

      // Dispose the overlay
      if ( this._overlayRef )
      {
          this._overlayRef.dispose();
      }
  }

  private _createOverlay(): void
  {
      this._overlayRef = this._overlay.create({
          hasBackdrop     : true,
          backdropClass   : 'fuse-backdrop-on-mobile',
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
                                .flexibleConnectedTo(this._sbrmodulosOrigin._elementRef.nativeElement)
                                .withLockedPosition(true)
                                .withPush(true)
                                .withPositions([
                                    {
                                        originX : 'start',
                                        originY : 'bottom',
                                        overlayX: 'start',
                                        overlayY: 'top'
                                    },
                                    {
                                        originX : 'start',
                                        originY : 'top',
                                        overlayX: 'start',
                                        overlayY: 'bottom'
                                    },
                                    {
                                        originX : 'end',
                                        originY : 'bottom',
                                        overlayX: 'end',
                                        overlayY: 'top'
                                    },
                                    {
                                        originX : 'end',
                                        originY : 'top',
                                        overlayX: 'end',
                                        overlayY: 'bottom'
                                    }
                                ])
      });
      // Detach the overlay from the portal on backdrop click
      this._overlayRef.backdropClick().subscribe(() => {
          this._overlayRef.detach();
      });
  }
}
